<template>
  <div>
    <div class="mb-4">
      <v-btn
        small
        class="green white--text mr-4"
        @click="createRecord"
      >
        新增紀錄
      </v-btn>

      <v-btn
        small
        class="green white--text"
        @click="toggleEditable"
      >
        編輯紀錄
      </v-btn>
    </div>

    <div class="mb-4">
      <v-btn
        small
        class="deep-orange lighten-2 white--text"
        @click="$helper.sumAllNumberInStringPopup()"
      >
        快速計算金額加總
      </v-btn>
    </div>


    <div class="pt-4">
      <record
        class="mb-4"
        @removeRecord="removeRecord"
        :editable="editable"
        v-for="(record, index) in records"
        :key="index"
        :index="index"
        :deleteIds="deleteIds"
        v-model="records[index]"
      />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: {
    popupConfig: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    loading: false,
    records: [],
    editable: false,
    deleteList: [],
  }),
  async mounted() {
    this.init()
    if (this.recommendedCosts.length === 0) {
      await this.loadRecommendedCost()
    }
  },
  methods: {
    init() {
      this.records = this.popupConfig.getRecords()
    },
    async loadRecommendedCost() {
      this.loading = true
      this.$emit('updateApplyProcessing', true)
      try {
        const result = await this.$api.collection.accountingApi.recommendedCost()
        this.$store.commit('accounting/setRecommendedCosts', result.data)
      } catch(error) {
        console.error(error)
      } finally {
        this.loading = false
        this.$emit('updateApplyProcessing', false)
      }
    },
    createRecord() {
      this.$apopup.base({
        width: '350px',
        title: `${dayjs(this.date).format('YYYY/MM/DD')} 新增紀錄`,
        bodySlot: () => import('./createRecordPopup.vue'),
        date: this.date,
        applyOnEnter: false,
        persistent: true,
        disabledApply: (data) => {
          if (!data) {
            return true
          }
          if (data.create.length === 0) {
            return true
          }
          return this.$helper.validRecords(data.create) == false
        },
        applyCallback: this.createRecordsRequest,
      })
    },
    async createRecordsRequest(applyData) {
      this.$emit('updateApplyProcessing', true)
      try {
        await this.$api.collection.accountingApi.saveRecords(
          applyData.create,
          [],
          [],
        )
      } catch(error) {
        console.error(error)
      } finally {
        this.$emit('updateApplyProcessing', false)
        await this.popupConfig.reloadRecords()
        this.init()
        await this.$nextTick()
        this.$emit('scrollToPopupBottom')
      }
    },
    toggleEditable() {
      this.editable = !this.editable
    },
    createCompareRecords(records) {
      if(!Array.isArray(records)) return records
      let result = window.eagleLodash.cloneDeep(records)
      return result.map(record => {
        record.amount = parseInt(record.amount)
        return record
      })
    },
    removeRecord(data) {
      const index = this.deleteIds.indexOf(data.record.id)
      if(index == -1) {
        this.deleteList.push(data.record)
      }

      else {
        this.$delete(this.deleteList, index)
      }
    },
  },
  computed: {
    date() {
      return this.popupConfig.date
    },
    recommendedCosts() {
      return this.$store.getters['accounting/recommendedCosts']
    },
    deleteIds() {
      return this.deleteList.map(record => record.id)
    },
    isChange() {
      if (this.deleteIds.length > 0) {
        return true
      }
      return !window.eagleLodash.isEqual(
        this.createCompareRecords(this.records),
        this.createCompareRecords(this.popupConfig.getRecords())
      )
    },
    updateList() {
      if (!Array.isArray(this.records)) {
        return []
      }
      return this.records.filter((record) => {
        return this.deleteIds.indexOf(record.id) == -1
      })
    },
    applyData() {
      return {
        isChange: this.isChange,
        update: this.updateList,
        delete: this.deleteList,
      }
    },
  },
  components: {
    record: () => import('./record.vue'),
  },
  watch: {
    applyData: {
      deep: true,
      handler() {
        this.$emit('updateData', this.applyData)
      }
    },
    editable: {
      handler(value) {
        this.deleteList = []
        this.records = window.eagleLodash.cloneDeep(this.popupConfig.getRecords())
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
</style>