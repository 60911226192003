<template>
  <div>
    <v-text-field
      v-model="text"
      label="請輸入包含數字的文字"
      class="mb-4"
      messages="可配合語音輸入文字使用起來更加方便"
      maxlength="200"
      autofocus
      outlined
      dense
    ></v-text-field>

    計算結果: {{result}}元
  </div>
</template>

<script>
export default {
  props: {
    popupConfig: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    text: '',
  }),
  async mounted() {
    this.init()
  },
  methods: {
    init() {
      this.text = this.popupConfig.data
    },
  },
  computed: {
    result() {
      return this.$helper.sumAllNumberInString(this.text)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped>
</style>