<template>
  <div class="pt-4">
    <v-card class="mb-8">
      <v-card-text>
        <div class="mb-4">
          <h1 class="text-h6 mb-4">快速搜尋</h1>
          <div class="mb-2">
            <v-btn
              color="blue"
              small
              class="white--text mr-4 mb-4"
              @click="searchThisMonth"
            >
              月初至今
            </v-btn>

            <v-btn
              color="blue"
              small
              class="white--text mr-4 mb-4"
              @click="searchLastMonth"
            >
              上個月
            </v-btn>

            <v-btn
              color="blue"
              small
              class="white--text mr-4 mb-4"
              @click="searchThisYear"
            >
              今年
            </v-btn>
          </div>
          <v-divider class="mb-8"></v-divider>
        </div>

        <div class="mb-4">
          <v-text-field
            label="關鍵字"
            @keydown.enter="search"
            @click:clear="search"
            dense
            outlined
            hide-details
            clearable
            v-model="requestParams.keywords"
          >
          </v-text-field>
        </div>

        <div class="mb-4">
          <datetime-picker
            label="起始日期"
            format="date"
            v-model="requestParams.startAt"
          ></datetime-picker>
        </div>

        <div class="mb-4">
          <datetime-picker
            label="結束日期"
            format="date"
            v-model="requestParams.endAt"
          ></datetime-picker>
        </div>

        <div class="mb-4">
          <v-select
            dense
            outlined
            multiple
            hide-details
            :items="recordTypes"
            label="類型"
            v-model="requestParams.recordTypes"
          ></v-select>
        </div>

        <div class="mb-4">
          <v-select
            dense
            outlined
            multiple
            clearable
            :items="costTypes"
            label="篩選特定開銷類型"
            messages="未選擇則不篩選"
            v-model="requestParams.costTypes"
          ></v-select>
        </div>

        <div class="mb-4">
          <v-btn
            color="green"
            block
            class="white--text"
            @click="search"
            @keyup.enter="search"
            :disabled="!dateValid"
          >
            搜尋
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <div v-if="hasRecord">
      <div class="mb-2">
        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('cost')">
          開銷: {{costTotal}}
        </v-chip>

        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('income')">
          收入: {{incomeTotal}}
        </v-chip>

        <v-chip
          label
          small
          class="white--text mr-2 mb-2"
          :color="$helper.getAccountingRecordTypeColorClass('tax')">
          稅務: {{taxTotal}}
        </v-chip>
      </div>

      <div class="mb-2">
        <v-chip
          v-for="item in costTypes" 
          :key="item.value"
          label
          small
          class="white--text mr-2 mb-2 blue"
        >
          {{$t(`cost.type.${item.value}`)}}: {{costBreakdown[item.value]}}
        </v-chip>
      </div>

      <div class="my-4" v-if="costTotal">
        <v-checkbox
          v-model="showChart"
          hide-details
          label="顯示圖表"
        >
        </v-checkbox>

        <div class="my-4" v-if="showChart">
          <costChart
            :costTotal="costTotal"
            :costBreakdown="costBreakdown"
          ></costChart>
        </div>
      </div>
    </div>

    <div v-if="hasRecord">
      <searchRecordDate
        v-for="date in dates"
        :key="date"
        :date="date"
      ></searchRecordDate>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import accountingConstants from 'modules/accounting/config/accountingConstants'
const maxLength = 1000

export default {
  metaInfo() {
    return {
      title: this.$t('搜尋記錄'),
    }
  },
  data: () => ({
    dateValid: false,
    showChart: false,
    requestParams: {
      keywords: null,
      startAt: null,
      endAt: null,
      recordTypes: accountingConstants.TYPES,
      costTypes: [],
    },
  }),
  beforeDestroy() {
    this.$store.commit('accounting/resetRecords')
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const routeQuery = this.$route.query
      this.requestParams.startAt = routeQuery.start ?? this.dateOfMonthStart
      this.requestParams.endAt = routeQuery.end ?? this.dateOfMonthEnd
    },
    searchThisMonth() {
      this.requestParams.startAt = this.dateOfMonthStart
      this.requestParams.endAt = this.dateOfMonthEnd
      this.search()
    },
    searchLastMonth() {
      this.requestParams.startAt = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
      this.requestParams.endAt = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
      this.search()
    },
    searchThisYear() {
      this.requestParams.startAt = dayjs().startOf('year').format('YYYY-MM-DD')
      this.requestParams.endAt = dayjs().endOf('year').format('YYYY-MM-DD')
      this.search()
    },
    async search() {
      if (!this.dateValid) {
        return
      }
      await this.$nextTick()
      this.$store.dispatch('loading/active')
      try {
        const result = await this.$api.collection.accountingApi.records({
          start_at: this.requestParams.startAt,
          end_at: this.requestParams.endAt,
          keywords: this.requestParams.keywords,
          filter_record_types: this.requestParams.recordTypes.length > 0 ? this.requestParams.recordTypes : null,
          filter_cost_types: this.requestParams.costTypes.length > 0 ? this.requestParams.costTypes : null,
        })
        this.$store.commit('accounting/setRecords', result)
      } catch(error) {
        console.error(error)
      } finally {
        this.$store.dispatch('loading/close')
      }
    },
    validateDates() {
      let startDate = dayjs(this.requestParams.startAt)
      const endDate = dayjs(this.requestParams.endAt)
      const dates = []
      if (startDate.isAfter(endDate)) {
        this.$snotify.warning('起始日期不可大於結束日期')
        this.dateValid = false
        return dates
      }

      const diff = endDate.diff(startDate, 'days')
      if (diff > maxLength) {
        this.$snotify.warning(`日期範圍不可超過${maxLength}天`)
        this.dateValid = false
        return dates
      }
      this.dateValid = true
    },
  },
  computed: {
    dateOfMonthStart() {
      return dayjs().startOf('month').format('YYYY-MM-DD')
    },
    dateOfMonthEnd() {
      return dayjs().endOf('month').format('YYYY-MM-DD')
    },
    dates() {
      let startDate = dayjs(this.requestParams.startAt)
      const endDate = dayjs(this.requestParams.endAt)
      const dates = []
      if (startDate.isAfter(endDate)) {
        return dates
      }
      while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
        dates.push(startDate.format('YYYY-MM-DD'))
        startDate = startDate.add(1, 'day')
        if (dates.length > 1000) {
          break
        }
      }
      return dates
    },
    hasRecord() {
      return this.$store.getters['accounting/hasRecord']
    },
    costTypes() {
      return accountingConstants.COST_TYPES.map((type) => ({
        text: this.$t(`cost.type.${type}`),
        value: type,
      }))
    },
    recordTypes() {
      return accountingConstants.TYPES.map((type) => ({
        text: this.$t(`record.type.${type}`),
        value: type,
      }))
    },
    incomeTotal() {
      return this.$store.getters['accounting/incomeTotal']
    },
    taxTotal() {
      return this.$store.getters['accounting/taxTotal']
    },
    costTotal() {
      return this.$store.getters['accounting/costTotal']
    },
    costBreakdown() {
      return this.$store.getters['accounting/costBreakdown']
    },
  },
  components: {
    costChart: () => import('@/modules/accounting/partials/costChart.vue'),
    searchRecordDate: () => import('modules/accounting/partials/searchRecords/searchRecordDate.vue')
  },
  watch: {
    'requestParams.startAt': {
      handler() {
        this.validateDates()
      },
    },
    'requestParams.endAt': {
      handler() {
        this.validateDates()
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass">
</style>
